import { api } from 'AurionCR/components';
import { ArrayType, PatchPartial } from 'utils/types';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { AxiosInstance, AxiosResponseTransformer } from 'axios';

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{0,7}$/gi.test(String(value));
};

export const axiosDateTransformer: AxiosResponseTransformer = (res) => {
  try {
    return JSON.parse(res, (_, value) => {
      if (typeof value === 'string' && isServerDateString(value)) {
        const _date = Date.parse(value);
        if (_date) {
          return `${value.split('.')[0]}.000Z`;
        }
      }
      return value;
    });
  } catch (e) {
    return res;
  }
};

export interface DynamicParams {
  select?: string;
  filter?: string;
  orderBy?: string;
  take?: number;
  skip?: number;
  count?: boolean;
}

export type DynamicResult<T extends any, P extends DynamicParams = {}> = P extends {
  count: boolean;
}
  ? { value: T[]; count: number }
  : { value: T[]; count: undefined };

export const transformDynamicToItem = <T extends { value: any[] } = { value: any[] }>(
  result: T,
) => {
  const item = result.value[0];
  if (!item) {
    throw new Error('record-not-found');
  }
  return item as ArrayType<T['value']>;
};

export const generateDynamicQuery = <T extends DynamicParams>(params: T) => {
  return Object.entries(params)
    .filter(([_, val]) => val !== undefined)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
};

interface DynamicModel {
  id: string;
}

interface DynamicServiceOptions<M> {
  engine?: AxiosInstance;
  getAll: string;
  post: string;
  patch: (data: PatchPartial<M, keyof M>) => string;
  delete: (data: PatchPartial<M, keyof M>) => string;
}

export class DynamicService<M = DynamicModel> {
  public engine: AxiosInstance = api;
  public urlGetAll: string;
  public urlPost: string;
  public urlPatch: (data: PatchPartial<M, keyof M>) => string;
  public urlDelete: (data: PatchPartial<M, keyof M>) => string;

  constructor(options: DynamicServiceOptions<M>) {
    const { getAll, patch, post, engine = this.engine } = options;

    this.engine = engine;

    this.urlGetAll = getAll;
    this.urlPatch = patch;
    this.urlPost = post;
    this.urlDelete = options.delete;

    this.getAllDynamic = this.getAllDynamic.bind(this);
    this.getDynamic = this.getDynamic.bind(this);
    this.patch = this.patch.bind(this);
    this.post = this.post.bind(this);
    this.delete = this.delete.bind(this);
  }

  async getAllDynamic<Model = M, Params extends DynamicParams = DynamicParams>(params: Params) {
    return this.engine.get<DynamicResult<Model, Params>>(this.urlGetAll, { params });
  }

  async getDynamic<Model = M, Params extends DynamicParams = DynamicParams>(params: Params) {
    const result = await this.getAllDynamic<Model, Params>({
      ...params,
      take: 1,
    });
    const data = result.data.value[0];

    if (!data) {
      throw new Error('record-not-found');
    }
    return { ...result, data };
  }

  async patch(data: Partial<M>) {
    return this.engine.patch(this.urlPatch(data as any), data);
  }

  async post(data: Omit<M, 'id'>) {
    return this.engine.post<M>(this.urlPost, data);
  }

  async delete(data: Partial<M>) {
    return this.engine.delete(this.urlDelete(data as any));
  }
}

export const decoratorSaveMixin = () => {
  return (propertyDescriptor: PropertyDescriptor) => {
    const origin = propertyDescriptor.value;

    propertyDescriptor.value = async function (formData: any) {
      let { data, mixins } = parseMixins({ ...formData });
      data = await saveMixins(data, mixins);
      return origin.call(this, data);
    };
  };
};
export const decoratorRank = <M extends Record<string, any>>(rankField: keyof M) => {
  return function (propertyDescriptor: PropertyDescriptor) {
    const origin = propertyDescriptor.value;

    propertyDescriptor.value = async function (formData: any) {
      const {
        data: { value },
        // @ts-ignore
      } = await this.getAllDynamic({
        take: 1,
        select: rankField,
        orderBy: `${rankField as string} desc `,
      });
      const rankValue = value[0] || { [rankField]: 0 };
      return origin.call(this, { ...formData, [rankField]: rankValue[rankField] + 1 });
    };
  };
};
