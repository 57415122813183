import React, { useCallback, useMemo } from 'react';
// @ts-ignore
import { Controller, UseFormMethods } from 'react-hook-form';
import { validateRule } from './heplers';

interface FormControllerProps<T> {
  onUpdate?: any;
  control: UseFormMethods['control'];
  rules?: any;
  errors?: any;
  name: keyof T;
  [x: string]: any;
}

function FormControllerComponent<FormModel, AsProps = {}>({
  onUpdate,
  rules,
  errors,
  ...rest
}: FormControllerProps<FormModel> & AsProps) {
  const rules_ = useMemo(() => (rules ? validateRule(rules) || rules : undefined), [rules]);
  const onChange_ = useCallback(
    ([e, value]: any[]) => {
      let value_ = '';
      if (
        e.target &&
        e.target.value !== undefined &&
        e.target.value !== null &&
        e.target.type !== 'checkbox'
      ) {
        value_ = e.target.value;
      } else if (value !== undefined && value !== null) {
        value_ = value;
      }
      // if (onUpdate) onUpdate(rest.name, e.target.value, value);
      return value_;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [onUpdate],
  );

  const _name = String(rest.name);

  return (
    // @ts-ignore
    <Controller
      {...rest}
      rules={rules_}
      error={errors ? errors[_name] : undefined}
      onChange={onChange_}
      name={_name}
      _name={_name}
    />
  );
}
export const FormController = React.memo(FormControllerComponent) as typeof FormControllerComponent;
export default FormController;
