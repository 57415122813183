import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import JoditEditor from 'jodit-react';
import { useDispatch } from 'react-redux';
import { InputLabel } from '@mui/material';
import { apiStatic, checkEs6AndRun, requestError } from '../../helpers';
import { Loading } from '../../loading';
import { validateURL } from '../validation';
import { notifyRequestResult } from '../../../store/modules/notify';
import { IEditorProps, IEditorRef, IEditorStaticProps } from './interfaces';

export const EditorStatic = memo<IEditorStaticProps>(
  ({
    label,
    value,
    onChange,
    onBlur,
    error,
    disabled = false,
    loading = false,

    config, // TODO: not allow to change dynamic
  }) => {
    const value_ = useMemo(() => {
      return typeof value !== 'string' ? '' : value;
    }, [value]);

    const config_ = useMemo(() => {
      return {
        buttons:
          'bold,italic,underline,|,ul,ol,outdent,indent,|,align,|,fontsize,paragraph,video,image,table,link,|,undo,redo,|,fullsize,print',
        toolbarAdaptive: false,
        toolbarSticky: false,
        uploader: { insertImageAsBase64URI: true },
        height: '20rem',
        cleanHTML: { denyTags: 'script iframe' },
        disabled,
        ...(config || {}),
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    const handleChange = useCallback(
      (value: string) => {
        onChange && onChange({ target: { value } }, value);
        onBlur && onBlur(value);
      },
      [onChange, onBlur],
    );
    return (
      <div className={`editor-wrapper${label ? ' label' : ''}`}>
        <div>
          {label && (
            <InputLabel shrink htmlFor="code-input" error={Boolean(error)}>
              {label}
            </InputLabel>
          )}
        </div>
        <JoditEditor
          value={value_}
          // @ts-ignore
          config={config_}
          onBlur={handleChange}
        />
        {Boolean(error) && <p className="error">{error?.message || ''}</p>}
        <Loading active={loading} />
      </div>
    );
  },
);

export const Editor = memo(
  forwardRef<IEditorRef, IEditorProps>(
    (
      {
        // api
        apiSet = 'MediaUploads/UploadHtmlToCloud',
        // eslint-disable-next-line
        apiGet = 'MediaUploads/GetHtmlFromCloud?fileName=${data}',
        // eslint-disable-next-line
        fileNamePrefixTemplate = '${data.id}',
        // file name
        // form fields
        name,
        label,
        value,
        onChange,
        error,
        disabled = false,
        // config
        config, //TODO: not allow to change dynamic
      },
      ref,
    ) => {
      const dispatch = useDispatch();

      const [loading, setLoading] = useState(false);
      const [content, setContent] = useState('');
      const [loadedContent, setLoadedContent] = useState('');

      const loadData = useCallback(
        (value: string) => {
          setLoading(true);
          apiStatic
            .get(checkEs6AndRun(apiGet, value))
            .then((response) => {
              setLoading(false);
              setContent(response.data);
              setLoadedContent(response.data);
            })
            .catch((error) => {
              console.error(error);
              setLoading(false);
              dispatch(notifyRequestResult(requestError(error), 'error'));
            });
          // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [setLoading, apiGet, setContent, setLoadedContent],
      );
      // init
      useEffect(() => {
        if (value) {
          if (typeof value === 'string' && !validateURL(value)) {
            loadData(value);
          } else if (value.value !== undefined) {
            setContent(value.value);
          } else {
            setContent('');
          }
        } else {
          setContent('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [value, setContent]);
      // callbacks
      const onChangeData = useCallback(
        (value: string) => {
          if (onChange) {
            if (!value && !loadedContent) {
              onChange('');
            } else {
              onChange({
                target: {
                  value: {
                    mixin_: {
                      requests: [
                        (data: any) => ({
                          method: 'post',
                          url: apiSet,
                          data: {
                            fileName: `${checkEs6AndRun(
                              fileNamePrefixTemplate,
                              data,
                            )}_${name}.html`,
                            filePath: 'HtmlParts',
                            htmlContent: value,
                          },
                        }),
                      ],
                      updateModel: 'filePath',
                      name,
                    },
                    value,
                  },
                },
              });
            }
          }
        },
        [onChange, apiSet, name, fileNamePrefixTemplate, loadedContent],
      );

      useImperativeHandle(
        ref,
        () => ({
          setContent: onChangeData,
        }),
        [onChangeData],
      );

      return (
        <EditorStatic
          label={label}
          error={error}
          disabled={disabled}
          value={content}
          config={config}
          onBlur={onChangeData}
          loading={loading}
        />
      );
    },
  ),
);

export default Editor;
