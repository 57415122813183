import { memo, useMemo } from 'react';
import { DateTimePickerToolbarProps, DateView, TimeView } from '@mui/x-date-pickers';
import { Box, Button, Typography } from '@mui/material';
import clsx from 'clsx';
import style from './index.module.scss';
import { format } from 'date-fns';
import { enUS, he } from 'date-fns/locale';
import { useIsRtl } from 'hooks';

interface ICustomDateTimePickerToolBar extends DateTimePickerToolbarProps<Date> {
  setOpenView: (view: DateView | TimeView) => void;
}

const CustomDateTimePickerToolBar = memo(
  ({ setOpenView, ...props }: ICustomDateTimePickerToolBar) => {
    const isRtl = useIsRtl();
    const locale = isRtl ? he : enUS;

    const current = useMemo(() => {
      const current_ = props.value || new Date();
      return {
        year: format(current_, 'yyyy'),
        day: format(current_, 'MMM dd', { locale }),
        time: format(current_, 'HH:mm'),
      };
    }, [props.value, locale]);

    return (
      <Box className={clsx(props.className, style.root)}>
        <Box className={style.dateWrapper}>
          <Button onClick={() => setOpenView('year')}>
            <Typography variant="h6" className={style.year}>
              {current.year}
            </Typography>
          </Button>

          <Button onClick={() => setOpenView('day')}>
            <Typography variant="h4" className={style.day}>
              {current.day}
            </Typography>
          </Button>
        </Box>

        <Box className={style.timeWrapper}>
          <Button onClick={() => setOpenView('hours')}>
            <Typography variant="h4" className={style.time}>
              {current.time}
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  },
);

export default CustomDateTimePickerToolBar;
