import React, { useEffect, useMemo } from 'react';
import style from './index.module.scss';
import { Drawer, IconButton } from '@mui/material';
import { useOpen } from 'AurionCR/components/hooks';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useAuth, useI18n, UserNav } from 'AurionCR/components';
import { LayoutIconsNav } from '../icons-nav';
import { AppUser } from 'services/account';
import LogoCircle from 'images/logo-small.svg';
import { AppMainNav } from '../main-nav';
import { useLocation } from 'react-router-dom';

interface Props {
  user: AppUser;
  fieldImagePath: keyof AppUser;
}
export const LayoutMobileNav: React.FC<Props> = ({ fieldImagePath }) => {
  const { isOpen, handleToggle, handleClose } = useOpen({ init: false });
  const { t } = useI18n();
  const { permission } = useAuth();
  const userNav = useMemo(() => {
    if (permission('isAllowToEditProfile')) {
      return [{ title: 'profile', path: '/profile' }];
    } else {
      return [];
    }
  }, [permission]);
  const location = useLocation();

  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <>
      <header className={style.header}>
        <IconButton onClick={handleToggle} color={'inherit'}>
          {isOpen ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        <div className={style.logo}>
          <img src={LogoCircle} alt={''} />
        </div>
      </header>
      <Drawer open={isOpen} onClose={handleToggle}>
        <aside className={style.aside}>
          <div className={style.main}>
            <div className={style.mainLight}>
              <UserNav
                menu={userNav}
                visibleTitle={false}
                fieldImagePath={fieldImagePath}
                endIcon={null}
              />
              <LayoutIconsNav />
            </div>
            <div className={style.mainRight}>
              <UserNav
                menu={userNav}
                visibleAvatar={false}
                fieldImagePath={fieldImagePath}
                title={(data: AppUser) => (
                  <>
                    <strong>{data.name}</strong>
                  </>
                )}
                endIcon={null}
              />
              <AppMainNav collapsed={false} accordion={true} />
            </div>
          </div>
          <div className={`copyright opened`}>
            <div>{t('login-copyright')}</div>
          </div>
        </aside>
      </Drawer>
    </>
  );
};
