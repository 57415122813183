import React, { useCallback, useEffect, useMemo } from 'react';
import { Drawer, IconButton } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { UserNav } from 'AurionCR/components/navigation/user';
import { LayoutIconsNav } from '../icons-nav';
import Logo from '../../../images/logo.svg?react';
import LogoCircle from '../../../images/logo-small.svg?react';
import { useAuth, useI18n } from 'AurionCR/components';
import { debounce } from 'lodash';
import { AppUser } from 'services/account';
import { useOpen } from 'AurionCR/components/hooks';
import { AppMainNav } from '../main-nav';

interface Props {
  user: AppUser;
  fieldImagePath: keyof AppUser;
}

export const LayoutDesktopNav: React.FC<Props> = ({ user, fieldImagePath }) => {
  const { permission } = useAuth();
  const { t } = useI18n();
  const [open, setOpen] = React.useState(true);
  const { isOpen: hover, setIsOpen: setHover } = useOpen({ init: false });
  const onToggle = useCallback(
    (bool: boolean) => {
      setOpen(bool);
      localStorage.setItem(`menu_user-${user.appUserID}`, JSON.stringify({ open: bool }));
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    },
    [setOpen, user],
  );
  const userNav = useMemo(() => {
    if (user && permission('isAllowToEditProfile')) {
      return [{ title: 'profile', path: '/profile' }];
    } else {
      return [];
    }
  }, [user, permission]);
  const onHover = useMemo(
    () =>
      debounce((value: boolean) => {
        setHover(value);
      }, 300),
    [setHover],
  );

  useEffect(() => {
    if (user && user.appUserID) {
      // @ts-ignore
      const store_ = JSON.parse(localStorage.getItem(`menu_user-${user.appUserID}`) || '{}');
      setOpen(store_ && store_.open !== undefined ? store_.open : true);
    }
  }, [setOpen, user]);
  return (
    <>
      <div className="root-left-sidebar">
        <div className="menu-controls">
          <IconButton onClick={() => onToggle(!open)}>
            {open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <UserNav
          menu={userNav}
          visibleTitle={false}
          fieldImagePath={fieldImagePath}
          endIcon={null}
        />
        <LayoutIconsNav />
      </div>
      <Drawer
        className={`root-drawer drawer ${open ? 'opened' : ''} ${hover ? 'hover' : ''}`}
        variant="permanent"
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      >
        <div className="logo">
          <Logo width={102} height={38} />
          <LogoCircle width={38} height={38} />
        </div>
        <UserNav
          menu={userNav}
          visibleAvatar={false}
          fieldImagePath={fieldImagePath}
          title={(data: AppUser) => (
            <>
              <strong>{data.name}</strong>
            </>
          )}
        />
        <AppMainNav collapsed={!open && !hover} accordion={true} />
        <div className={`copyright ${open ? 'opened' : ''}`}>
          <div>{t('login-copyright')}</div>
        </div>
      </Drawer>
    </>
  );
};
