import React, { createRef, memo, useCallback, useEffect, useState } from 'react';
import { IRoute } from 'AurionCR/components/router';
import { useSelector } from 'react-redux';
import { List } from '@mui/material';
import { MenuItem } from 'AurionCR/components';
import { useLocation } from 'react-router-dom';
import { AppScrollbars } from 'components/app-scrollbar';
import { useIsRtl } from 'hooks';
import useRouter from 'AurionCR/components/router';

interface Props {
  collapsed: boolean;
  accordion: boolean;
}

export const AppMainNav = memo<Props>(({ collapsed = false, accordion = false }) => {
  const { push } = useRouter();
  const { pathname } = useLocation();
  const routes = useSelector((state) => (state as any).routes.routes);
  const goTo = useCallback(
    (path: string) => {
      push({ pathname: path });
    },
    [push],
  );
  const [elRefs, setElRefs] = React.useState([]);
  const [menu, setMenu] = useState<any>(null);
  const onOpen = useCallback(
    (state: boolean, ref: any) => {
      if (state && accordion) {
        elRefs.forEach((item: any) => {
          if (ref !== item) item?.current?.setOpen(false);
        });
      }
    },
    [accordion, elRefs],
  );

  useEffect(() => {
    if (routes && routes.length) {
      const menu_ = routes.filter((route: IRoute) => route.inMenu);
      setElRefs(menu_.map(() => createRef()));
      setMenu(menu_);
    }
  }, [routes]);
  const isRtl = useIsRtl();

  return menu ? (
    <AppScrollbars isRtl={isRtl} autoHide={collapsed} autoHideTimeout={0} autoHideDuration={0}>
      <nav className={`main-nav ${collapsed ? 'collapsed' : ''}`}>
        <List component="div" disablePadding>
          {menu.map((route: IRoute, i: number) => (
            <MenuItem
              key={route.path}
              ref={elRefs[i]}
              route={route}
              pathname={pathname}
              collapsed={collapsed}
              onOpen={onOpen}
              level={1}
              goTo={goTo}
            />
          ))}
        </List>
      </nav>
    </AppScrollbars>
  ) : null;
});
