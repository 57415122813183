import React, { ReactNode, useEffect, useState } from 'react';
import { api, apiStatic } from 'AurionCR/components';
import axios, { AxiosResponseTransformer } from 'axios';
import { axiosDateTransformer } from 'utils/service';

const instances = [apiStatic, api, axios];

interface Props {
  children: ReactNode;
}

export const AxiosInterceptorGet: React.FC<Props> = ({ children }) => {
  const [canRender, setCenRender] = useState(false);

  useEffect(() => {
    instances.forEach((instance) => {
      let transformers = [
        axiosDateTransformer,
        ...(Array.isArray(instance.defaults.transformResponse)
          ? instance.defaults.transformResponse
          : [instance.defaults.transformResponse].filter(Boolean)),
      ];
      instance.defaults.transformResponse = transformers.filter(
        Boolean,
      ) as AxiosResponseTransformer[];
    });
    setCenRender(true);
  }, []);

  return canRender ? <>{children}</> : null;
};
