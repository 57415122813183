import { memo, useMemo } from 'react';
import { InputLabel } from '@mui/material';
import { Rating as MaterialRating } from '@mui/lab';

export const Rating = memo(({ label, error, value, ...props }: any) => {
  const value_ = useMemo(() => Number(value) || 0, [value]);
  return (
    <div className={`editor-wrapper${label ? ' label' : ''}`}>
      {label && (
        <InputLabel shrink htmlFor="code-input" error={Boolean(error)}>
          {label}
        </InputLabel>
      )}
      <MaterialRating {...props} value={value_} />
      {Boolean(error) && <p className="error">{error.message || ''}</p>}
    </div>
  );
});
export default Rating;
