import React, { memo } from 'react';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay';
import { isSameDay, isWithinInterval, max, min } from 'date-fns';
import { PickersDay } from '@mui/x-date-pickers';
import clsx from 'clsx';
import { maxDate, minDate } from 'utils/dates';
import { Box } from '@mui/material';
import style from './index.module.scss';
import { Classes } from '../../index';

interface ComponentSlotInterface {
  day: Date;
  start: Date;
  end: Date;
  hoverDay: Date | undefined;
  setHoverDay: React.Dispatch<React.SetStateAction<Date | undefined>>;
  className?: string;
  classes?: Partial<Classes>;
}

const DateRangeSlot = memo<ComponentSlotInterface & PickersDayProps<any>>(
  ({ day, start, end, hoverDay, setHoverDay, classes, className, ...rest }) => {
    const isFirstDay = Boolean(start && isSameDay(start, day));
    const isLastDay = Boolean(end && isSameDay(end, day));

    const isInTheInterval =
      start &&
      end &&
      isWithinInterval(day, {
        start: min([start, end]),
        end: max([start, end]),
      });

    const isHighlight = Boolean(isInTheInterval || isFirstDay || isLastDay);

    const previewStart = minDate(start, hoverDay);
    const previewEnd = maxDate(start, hoverDay);

    const isPreview =
      start &&
      !end &&
      hoverDay &&
      isWithinInterval(day, {
        start: previewStart,
        end: previewEnd,
      });

    const isSelectedOneDay = start && end && isSameDay(start, end);

    return (
      <Box
        onMouseEnter={() => setHoverDay(day)}
        onMouseLeave={() => setHoverDay(undefined)}
        className={clsx(style.dayWrapper, classes?.dayWrapper, {
          [style.dayWrapperHighlight]: isHighlight,
          [style.dayWrapperHighlightStart]: isHighlight && isFirstDay,
          [style.dayWrapperHighlightEnd]: isHighlight && isLastDay,

          [style.dayWrapperPreview]: isPreview,
          [style.dayWrapperPreviewStart]: isPreview && isSameDay(previewStart, day),
          [style.dayWrapperPreviewEnd]: isPreview && isSameDay(previewEnd, day),

          [style.dayWrapperTheSame]: isSelectedOneDay,
        })}
      >
        <PickersDay
          {...rest}
          day={day}
          selected={isFirstDay || isLastDay}
          className={clsx(className, style.day, classes?.day, className)}
        />
      </Box>
    );
  },
);

export default DateRangeSlot;
