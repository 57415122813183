import { memo, useMemo } from 'react';
import { DatePickerToolbarProps, DateView } from '@mui/x-date-pickers';
import { Box, Button, Typography } from '@mui/material';
import clsx from 'clsx';
import style from './index.module.scss';
import { format } from 'date-fns';
import { useIsRtl } from 'hooks';
import { enUS, he } from 'date-fns/locale';

interface ICustomDatePickerToolBar extends DatePickerToolbarProps<Date> {
  setOpenView: (view: DateView) => void;
}

const CustomDatePickerToolBar = memo(({ setOpenView, ...props }: ICustomDatePickerToolBar) => {
  const isRtl = useIsRtl();
  const locale = isRtl ? he : enUS;
  const current = useMemo(() => {
    const current_ = props.value || new Date();
    return {
      year: format(current_, 'yyyy'),
      day: format(current_, 'EEE, MMM dd', { locale }),
    };
  }, [props.value, locale]);

  return (
    <Box className={clsx(props.className, style.root)}>
      <Button onClick={() => setOpenView('year')}>
        <Typography variant="h6" className={style.year}>
          {current.year}
        </Typography>
      </Button>
      <Button onClick={() => setOpenView('day')}>
        <Typography variant="h4" className={style.day}>
          {current.day}
        </Typography>
      </Button>
    </Box>
  );
});

export default CustomDatePickerToolBar;
