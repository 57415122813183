import { memo, useContext, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DatePickerToolbarProps, DateView } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { DateRangePickerContext } from '../../index';
import clsx from 'clsx';
import style from './index.module.scss';
import { useIsRtl } from 'hooks';
import { enUS, he } from 'date-fns/locale';

interface ICustomToolbar extends DatePickerToolbarProps<Date> {
  setOpenView: (view: DateView) => void;
}

export const CustomDateRangePickerToolBar = memo(({ setOpenView, ...props }: ICustomToolbar) => {
  const context = useContext(DateRangePickerContext);
  const isRtl = useIsRtl();
  const locale = isRtl ? he : enUS;

  const current = useMemo(() => {
    const current_ = props.value || new Date();
    return {
      year: format(current_, 'yyyy', { locale }),
      month: format(current_, 'MMMM', { locale }),
    };
  }, [props.value, locale]);

  const dateText = useMemo(() => {
    const dates_: any[] = [context.begin, context.end].sort((a, b) => a - b);
    return `${dates_[0] ? format(dates_[0], 'yyyy.MM.dd') : '---.--.--'} - ${dates_[1] ? format(dates_[1], 'yyyy.MM.dd') : '---.--.--'} `;
  }, [context]);

  return (
    <Box className={clsx(props.className, style.root)}>
      <div className={style.controls}>
        <Button
          onClick={() => setOpenView('year')}
          className={style.year}
          color="primary"
          size="small"
          variant="contained"
        >
          {current.year}
        </Button>
        <Button
          onClick={() => setOpenView('month')}
          className={style.month}
          color="primary"
          size="small"
          variant="contained"
        >
          {current.month}
        </Button>
      </div>
      <Typography variant={'h5'} className={style.title}>
        {dateText}
      </Typography>
    </Box>
  );
});
