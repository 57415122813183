import { DialogTitle, Dialog, DialogContent, DialogContentText } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import React from 'react';

export interface DialogAlertProps {
  withIcon?: boolean;
  message?: string;
  title?: string;
  onClose?: () => void;
}

export const DialogAlert: React.FC<DialogAlertProps> = ({
  withIcon = true,
  message,
  title,
  onClose,
}) => {
  return (
    <Dialog onClose={onClose} aria-labelledby="dialog-alert" open={true} className={'dialog-alert'}>
      <DialogTitle>
        {withIcon && <ErrorOutline className="dialog-alert__icon" />}
        {!!title && title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
