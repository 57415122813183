export enum PERMISSIONS {
  EDIT_WEBSITES = 'isAllowToEditWebsites',
  EDIT_SETTINGS = 'isAllowToEditSetting',
  EDIT_PHARMA_COMPANIES = 'isAllowToEditPharmaCompanies',
  EDIT_LABELS = 'isAllowToEditLabels',
  EDIT_PROFILE = 'isAllowToEditProfile',
  EDIT_CRM_USERS = 'isAllowToEditCRMUsers',
  EDIT_PERMISSIONS = 'isAllowToEditPermissions',
  VIEW_LOG = 'isAllowToViewLog',
  VIEW_SENSITIVE = 'isAllowToViewSensitiveInformation',
  RESET_SENSITIVE = 'isAllowToResetSensitiveInformation',
}

export const EDITOR_CONFIG_LIGHT = {
  buttons: 'bold,|,ul,ol,|,align,|,fontsize,link,|,undo,redo',
};

const excelGridColumns = [
  {
    title: 'title-of-name',
    field: 'titleOfName.title as title',
  },
  {
    title: 'first-name',
    field: 'firstName as firstName',
  },
  {
    title: 'last-name',
    field: 'lastName as lastName',
  },
  {
    title: 'mobile-phone',
    field: 'mobilePhone as mobilePhone',
  },
  {
    title: 'email',
    field: 'email as email',
  },
  {
    title: 'expert-type',
    field: 'expertType.title as expertTypeTitle',
  },
  {
    title: 'position',
    field: 'position as position',
  },
  {
    title: 'primary-medical-field',
    field: 'primaryMedicalField.title as primaryMedicalFieldTitle',
  },
  {
    title: 'hmo',
    field: 'hmo.title as hmoTitle',
  },
  {
    title: 'hmo-name',
    field: 'hmoName as hmoName',
  },
  {
    title: 'hmo-city',
    field: 'hmoCity.title as hmoCityTitle',
  },
  {
    title: 'hospital',
    field: 'hospital.title as hospitalTitle',
  },
  {
    title: 'hospital-position',
    field: 'hospitalPosition as hospitalPosition',
  },
  {
    title: 'hospital-department',
    field: 'hospitalDepartment.title as hospitalDepartmentTitle',
  },
  {
    title: 'private-work-place-name',
    field: 'privateWorkPlaceMedicalPractice as pw',
  },
  {
    title: 'other-work-place',
    field: 'otherWorkPlace.title as otherWorkPlaceTitle',
  },
  {
    title: 'license-number',
    field: 'externalCertificateLicenseNumber as license',
  },
  {
    title: 'is-allow-sms',
    field: 'isAllowSMS as isSms',
    template: 'boolean',
  },
];

export const createExcelGridColumns = (prefix?: string) => {
  return excelGridColumns.map((item) => {
    return { ...item, field: [prefix, item.field].filter((v) => v).join('.') };
  });
};

export const APP_FORMAT_DATE = 'dd/MM/yyyy';
export const APP_FORMAT_TIME = 'HH:mm';
export const TIME_FORMAT_DATE = 'yyyy-MM-dd';
export const APP_FORMAT_DATE_TIME = `${APP_FORMAT_DATE} ${APP_FORMAT_TIME}`;
