import { useState, createContext, FC, useMemo } from 'react';
import { isWithinInterval, max, min } from 'date-fns';
import {
  DateCalendarClasses,
  DateCalendarProps,
  DateView,
  StaticDatePicker,
} from '@mui/x-date-pickers';
import { convertToDate } from 'utils/dates';
import CustomDatePickerHeader from '../components/custom-date-picker-header';
import { CustomDateRangePickerToolBar } from './components/date-range-picker-toolbar/date-range-picker-toolbar';
import DateRangeSlot from './components/date-range-slot';

export interface Classes extends DateCalendarClasses {
  dayWrapper: string;
  dayWrapperHover: string;
  dayWrapperRange: string;
  dayWrapperStart: string;
  dayWrapperEnd: string;
  day: string;
}

export interface ICalendarPickerStrategy {
  (pikedDate: Date | null, currentValue: Date[]): Date[];
}

export const strategyCalendarPickerRange: ICalendarPickerStrategy = (pikedDate, currentValue) => {
  const [start, end] = currentValue;
  if (!pikedDate) return [];
  if (!start) return [pikedDate];
  if (!end) return [start, pikedDate].sort((a, b) => Number(a) - Number(b));
  if (start && end) {
    if (
      isWithinInterval(pikedDate, {
        start: min([start, end]),
        end: max([start, end]),
      })
    ) {
      return Math.abs(Number(start) - Number(pikedDate)) < Math.abs(Number(end) - Number(pikedDate))
        ? [start, pikedDate]
        : [pikedDate, end];
    }
    return [pikedDate];
  }
  return [];
};

export const DateRangePickerContext = createContext<any>({ begin: undefined, end: undefined });

export interface IDateRangePicker extends Omit<DateCalendarProps<any>, 'date' | 'onChange'> {
  className?: string;
  classes?: Partial<Classes>;
  value: (Date | string)[];
  onChange: (value: Date[]) => void;
  pickerStrategy?: ICalendarPickerStrategy;
}

const DateRangePicker: FC<IDateRangePicker> = ({
  value: outerValue,
  onChange,
  classes,
  className,
  pickerStrategy = strategyCalendarPickerRange,
  ...rest
}) => {
  const [innerValue, onChangeInner] = useState<Date[]>([]);
  const [hoverDay, setHoverDay] = useState<Date>();
  const [dateView, setDateView] = useState<DateView>('day');
  const isCalendarHeader = dateView === 'day';

  const pickerValue = useMemo(() => {
    if (outerValue) {
      return outerValue.filter(Boolean).map(convertToDate);
    }
    return innerValue;
  }, [outerValue, innerValue]);

  const pickerOnChange = onChange === undefined ? onChangeInner : onChange;

  const start = pickerValue[0];
  const end = pickerValue[1];

  const componentSlotProps = useMemo(() => {
    return { start, end, setHoverDay, hoverDay, classes, className };
  }, [start, end, setHoverDay, hoverDay, classes, className]);

  return (
    <DateRangePickerContext.Provider value={{ begin: start, end: end }}>
      <StaticDatePicker
        {...rest}
        slots={{
          toolbar: (props) => <CustomDateRangePickerToolBar {...props} setOpenView={setDateView} />,
          calendarHeader: CustomDatePickerHeader,
          day: (props) => {
            return <DateRangeSlot {...props} {...componentSlotProps} />;
          },
        }}
        slotProps={{
          day: {
            selectedDay: innerValue,
          } as any,
          actionBar: {
            sx: { display: 'none', height: 0 },
          },
        }}
        view={dateView}
        views={['year', 'month', 'day']}
        value={start}
        onChange={(date) => {
          pickerOnChange(pickerStrategy(date, pickerValue));
        }}
        onViewChange={(view) => setDateView(view)}
        sx={{
          '.MuiPickersLayout-contentWrapper': {
            borderBottom: '1px solid #ebeeef',
          },
          ...(!isCalendarHeader
            ? {
                '.MuiDateCalendar-root': {
                  display: 'grid',
                  alignItems: 'center',
                },
                '.muiltr-1hp44o5': {
                  display: 'none',
                },
              }
            : {}),
        }}
        orientation={'portrait'}
      />
    </DateRangePickerContext.Provider>
  );
};
export default DateRangePicker;
