import {
  forwardRef,
  memo,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { IRoute } from '../router';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useAuth } from '../auth';

export const checkActive = (pathname: string, path: string): boolean => {
  // eslint-disable-next-line
  return path === pathname || (path.length > 1 && new RegExp(`${path}(\/|$)`, 'g').test(pathname));
};
export const MenuItem = memo(
  forwardRef(
    ({ route, pathname, collapsed, level, goTo, onOpen, onMenuOpen }: any, ref: Ref<any>) => {
      const auth = useAuth();
      const children = useMemo(() => {
        if (route.routes) {
          return route.routes.filter(
            (route: IRoute) => route.inMenu && auth.permission(route.permission),
          );
        } else {
          return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [route]);

      const [active, setActive] = useState(checkActive(pathname, route.path));
      const [open, setOpen] = useState(checkActive(pathname, route.path));
      const onClick = useCallback(() => {
        if (route.component) {
          if (onOpen) onOpen(true, ref, route);
          goTo(route.path);
        } else {
          const open_ = !open;
          if (onOpen) onOpen(open_, ref, route);
          setOpen(open_);
        }
        onMenuOpen && onMenuOpen({ isAccordionOpen: open, ref, route });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [route, setOpen, onOpen, open, onMenuOpen]);
      const iconCollapsed = useMemo(() => {
        if (children.length) {
          return !collapsed && open ? (
            <ExpandLess className="icon-collapsed" />
          ) : (
            <ExpandMore className="icon-collapsed" />
          );
        } else {
          return null;
        }
      }, [children, open, collapsed]);
      const iconRoute = useMemo(() => {
        if (route.icon) {
          if (typeof route.icon === 'string') {
            return (
              <ListItemIcon className="icon-wrapper">
                <span className={`route-icon ${route.icon}`} />
              </ListItemIcon>
            );
          } else {
            return <ListItemIcon className="icon-wrapper">{route.icon.type.render()}</ListItemIcon>;
          }
        } else {
          return null;
        }
      }, [route]);
      useEffect(() => {
        setActive(checkActive(pathname, route.path));
      }, [route.path, setActive, pathname]);
      // eslint-disable-next-line
      useImperativeHandle(ref, () => ({ setOpen }));
      // eslint-disable-next-line
      if (!auth.permission(route.permission) || (route.routes && !children.length)) return null;
      return (
        <>
          <ListItem button onClick={onClick} className={`level-${level} ${active ? 'active' : ''}`}>
            {iconRoute}
            <ListItemText
              className="title"
              color="primary"
              primary={route.title}
              secondary={children.map((item: IRoute) => item.title).join(', ')}
            />
            {iconCollapsed}
          </ListItem>
          {children.length > 0 && (
            <Collapse in={!collapsed && open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {children.map((item: IRoute) => (
                  <MenuItem
                    key={item.path}
                    route={item}
                    pathname={pathname}
                    collapsed={collapsed}
                    onMenuOpen={onMenuOpen}
                    level={level + 1}
                    goTo={goTo}
                  />
                ))}
              </List>
            </Collapse>
          )}
        </>
      );
    },
  ),
);
