import { FC, ReactNode } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import './_style.scss';
import { useCurrentUser } from 'hooks';
import { LayoutDesktopNav } from './desktop-nav';
import { LayoutMobileNav } from './mobile-nav';

const FIELD_IMAGE_PATH = 'userPhoto';

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  const user = useCurrentUser();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <div className="root-content">
      {user && (
        <>
          {isMobile ? (
            <LayoutMobileNav user={user} fieldImagePath={FIELD_IMAGE_PATH} />
          ) : (
            <LayoutDesktopNav user={user} fieldImagePath={FIELD_IMAGE_PATH} />
          )}
        </>
      )}
      <main className="root-page-content">{children}</main>
    </div>
  );
};

export default Layout;
