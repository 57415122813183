import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { getQuarter, format, addYears, subMilliseconds, addMonths, Quarter, Month } from 'date-fns';
import { useI18n } from '../../i18';
import { enUS } from 'date-fns/locale';
import { Button, Popper, Tab, Tabs, ClickAwayListener, InputAdornment } from '@mui/material';
import Select from '../select';
import DateRangePicker from './date-range-picker';
import Input from '../input';
import DateRangeIcon from '@mui/icons-material/DateRange';

const getDatesByYear = (year: number) => {
  const from_ = new Date(year, 0, 1);
  return [from_, subMilliseconds(addYears(from_, 1), 1)];
};
const getDatesByQuarter = (year: number, quarter: number) => {
  const from_ = new Date(year, quarter * 3 - 3, 1);
  return [from_, subMilliseconds(addMonths(from_, 3), 1)];
};
const getDatesByMonth = (year: number, month: number) => {
  const from_ = new Date(year, month - 1, 1);
  return [from_, subMilliseconds(addMonths(from_, 1), 1)];
};

export const DataMultipleContent = memo(({ value, onChange }: { value: any[]; onChange: any }) => {
  const { t } = useI18n();
  const [tab, setTab] = React.useState(0);
  // sources
  const [year, setYear] = useState(new Date().getFullYear());
  const [quarter, setQuarter] = useState(1);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [dates, setDates] = useState<any[]>([]);
  const DSYear = useMemo(
    () => [...Array(10)].map((_, i) => ({ id: year - i })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const DSQuarter = useMemo(
    () =>
      [...Array(4)].map((_, i) => ({
        title: enUS.localize?.quarter((i + 1) as Quarter, { width: 'abbreviated' }),
        id: i + 1,
      })),
    [],
  );
  const DSMonth = useMemo(
    () =>
      [...Array(12)].map((_, i) => ({
        title: enUS.localize?.month(i as Month, { width: 'abbreviated' }),
        id: i + 1,
      })),
    [],
  );

  const onChangeTab = useCallback(
    (_event: React.ChangeEvent<{}>, value: number) => {
      setTab(value);
      window.dispatchEvent(new Event('resize'));
    },
    [setTab],
  );

  const onChangeProvider = useCallback(
    (dates: Date[]) => {
      onChange(dates);
    },
    [onChange],
  );
  const onApply = useCallback(() => {
    switch (tab) {
      case 0:
        onChangeProvider([...dates]);
        break;
      case 1:
        onChangeProvider(getDatesByYear(year));
        break;
      case 2:
        onChangeProvider(getDatesByQuarter(year, quarter));
        break;
      case 3:
        onChangeProvider(getDatesByMonth(year, month));
        break;
    }
  }, [tab, year, quarter, month, dates, onChangeProvider]);

  const onChangeYear = useCallback(
    (e: any) => {
      setYear(e.target.value);
      setDates(getDatesByYear(e.target.value));
    },
    [setYear, setDates],
  );
  const onChangeQuarter = useCallback(
    (e: any) => {
      const value_ = e.target.value;
      setQuarter(value_);
      setMonth(value_ * 3 - 2);
      setDates(getDatesByQuarter(year, value_));
    },
    [year, setQuarter, setMonth, setDates],
  );
  const onChangeMonth = useCallback(
    (e: any) => {
      const value_ = e.target.value;
      setMonth(value_);
      setQuarter(getQuarter(new Date(year, value_ - 1, 1)));
      setDates(getDatesByMonth(year, value_));
    },
    [year, setMonth, setQuarter, setDates],
  );
  const onChangeDates = useCallback(
    (value: any) => {
      setDates([...value]);
      setYear(value[0].getFullYear());
      setQuarter(getQuarter(value[0]));
      setMonth(value[0].getMonth() + 1);
    },
    [setYear, setQuarter, setMonth, setDates],
  );

  const parseValue = useCallback(
    (value: Date[] | undefined[]) => {
      // const from = convertDateUtcToLocal(value[0]) || new Date();
      // const to = convertDateUtcToLocal(value[1]);
      const from = value[0] || new Date();
      const to = value[1];

      setYear(from.getFullYear());
      setQuarter(getQuarter(from));
      setMonth(from.getMonth() + 1);
      setDates(from && to ? [from, to] : []);
    },
    [setYear, setQuarter, setMonth, setDates],
  );

  // init
  useEffect(() => {
    parseValue(value);
  }, [value, parseValue]);

  return (
    <div className="date-multiple_wrapper">
      <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={onChangeTab}>
        <Tab label={t('from-to')} />
        <Tab label={t('yearly')} />
        <Tab label={t('quarterly')} />
        <Tab label={t('monthly')} />
      </Tabs>
      <div className="tabs-content">
        {tab === 0 && (
          <div className="tab-content">
            <DateRangePicker value={dates} onChange={onChangeDates} />
          </div>
        )}
        {tab !== 0 && (
          <div className="tab-content selects">
            <Select
              settings={{ label: 'year', source: DSYear, option: { label: 'id', value: 'id' } }}
              onChange={onChangeYear}
              value={year}
              disableClearable
              autoFocus
            />
            {tab === 2 && (
              <Select
                settings={{ label: 'quarter', source: DSQuarter }}
                onChange={onChangeQuarter}
                value={quarter}
                disableClearable
              />
            )}
            {tab === 3 && (
              <Select
                settings={{ label: 'month', source: DSMonth }}
                onChange={onChangeMonth}
                value={month}
                disableClearable
              />
            )}
          </div>
        )}
      </div>
      <div className="controls">
        <Button onClick={() => onChange([])} color="primary" size="small">
          {t('clear')}
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={onApply}>
          {t('apply')}
        </Button>
      </div>
    </div>
  );
});

export const DateMultiple = memo(({ value, onChange, ...rest }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      // @ts-ignore
      setAnchorEl(event.currentTarget);
      setOpen(true);
    },
    [setAnchorEl, setOpen],
  );
  const onClear = useCallback(() => {
    onChange([]);
  }, [onChange]);
  const onClose = useCallback(() => {
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAnchorEl, setOpen]);

  const title = useMemo(() => {
    onClose();
    if (value[0] && value[1]) {
      // const from: any = convertDateUtcToLocal(value[0]);
      // const to: any = convertDateUtcToLocal(value[1]);
      // return `${format(from, 'yyyy.MM.dd')} - ${format(to, 'yyyy.MM.dd')}`;
      return `${format(value[0], 'yyyy.MM.dd')} - ${format(value[1], 'yyyy.MM.dd')}`;
    } else {
      return '';
    }
  }, [value, onClose]);

  return (
    <>
      <Input
        {...rest}
        className={'data-range_input'}
        value={title}
        onClick={onOpen}
        onClear={onClear}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DateRangeIcon />
            </InputAdornment>
          ),
        }}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        className="date-multiple_tooltip"
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={onClose}>
          <div>
            <DataMultipleContent value={value} onChange={onChange} />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
});
