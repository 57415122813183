import React, { ReactNode, useEffect } from 'react';
import { api, apiStatic } from 'AurionCR/components';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { prepareDates, prepareIDs } from 'utils';

const instances = [apiStatic, api, axios];
const prepareConfig = (conf: InternalAxiosRequestConfig) => {
  if (conf.method === 'post' && conf.data && typeof conf.data === 'object') {
    conf.data = prepareDates(prepareIDs(conf.data));
  }
  return conf;
};

interface Props {
  children: ReactNode;
}

export const AxiosInterceptorPost: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    instances.map((instance) => {
      return instance.interceptors.request.use(prepareConfig);
    });
  }, []);

  return <>{children}</>;
};
