import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import React, { memo } from 'react';
import { enUS, he } from 'date-fns/locale';
import { useIsRtl } from 'hooks';

interface Props {
  children: React.ReactNode;
}
export const DatePickerProvider = memo<Props>(({ children }) => {
  const isRTL = useIsRtl();
  const locale = isRTL ? he : enUS;

  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
      {children}
    </LocalizationProvider>
  );
});
