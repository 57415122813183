import { FC } from 'react';
import 'AurionCR/styles/_main.scss';
import 'styles/app.global.scss';
import { Provider } from 'react-redux';
import { store } from 'AurionCR/store';
import Page404 from 'AurionCR/pages/Page404';
import Layout from 'pages/Layout';
import routesConfig from 'configs/routes';
import { SnackbarProvider } from 'notistack';
import {
  AuthContextProvider,
  I18nContextProvider,
  LoadingOverlayProvider,
  Notifier,
  Routes,
} from 'AurionCR/components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AxiosInterceptors } from 'contexts/axios-interceptors';
import { AppThemeProvider } from 'contexts/theme-provider';
import { DatePickerProvider } from './contexts/date-picker';

const App: FC = () => {
  window.authCookieName_ = 'vimed_global_auth_token_crm';
  window.authRefreshCookieName_ = 'vimed_global_auth_refresh_token_crm';
  window.authDateCookieName_ = 'vimed_global_auth_expires_token_crm';

  return (
    <Provider store={store}>
      <AxiosInterceptors>
        <AuthContextProvider>
          <DndProvider backend={HTML5Backend}>
            <I18nContextProvider apiLabelsFilter='labelSource.key == "crm"'>
              <AppThemeProvider>
                <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                  <Notifier />
                  <DatePickerProvider>
                    <LoadingOverlayProvider>
                      <Routes layout={Layout} routes={routesConfig} page404={Page404} />
                    </LoadingOverlayProvider>
                  </DatePickerProvider>
                </SnackbarProvider>
              </AppThemeProvider>
            </I18nContextProvider>
          </DndProvider>
        </AuthContextProvider>
      </AxiosInterceptors>
    </Provider>
  );
};

export default App;
