import { memo, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { InputLabel } from '@mui/material';

export const EditorInline = memo(
  ({
    label,
    placeholder = '',
    value,
    onChange,
    error,
    disabled = false,
    // config
    config,
  }: {
    label?: string;
    placeholder?: string;
    value?: any;
    onChange?: any;
    error?: any;
    disabled?: boolean;

    config?: any;
  }) => {
    const config_ = useMemo(() => {
      return {
        buttons: 'bold,italic,|,ul,ol,|,undo,redo,eraser',
        toolbarAdaptive: false,
        toolbarSticky: false,
        uploader: { insertImageAsBase64URI: true },
        height: '20rem',
        limitChars: 500,
        placeholder,
        disabled,
        ...(config || {}),
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, config]);

    return (
      <div className={`editor-wrapper${label ? ' label' : ''}`}>
        {label && (
          <InputLabel shrink htmlFor="code-input" error={Boolean(error)}>
            {label}
          </InputLabel>
        )}
        <JoditEditor
          value={value}
          config={config_}
          onBlur={(value) => onChange({ target: { value } }, value)}
          // onChange={newContent => {
          // console.log('onChange', newContent);
          // }}
        />
        {Boolean(error) && <p className="error">{error.message || ''}</p>}
      </div>
    );
  },
);

export default EditorInline;
