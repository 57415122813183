import { lazy } from 'react';
import { IRoute } from 'AurionCR/components/router';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import { PERMISSIONS } from './constants';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import EventIcon from '@mui/icons-material/Event';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';

interface IRouteApp extends IRoute {
  permission?: PERMISSIONS;
  routes?: undefined | IRouteApp[];
}

const routes: IRouteApp[] = [
  {
    path: '/',
    title: 'dashboard',
    icon: DashboardIcon,
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ 'pages/Dashboard')),
  },
  {
    path: '/members',
    title: 'members',
    // permission: PERMISSIONS.EDIT_CRM_USERS,
    icon: PeopleIcon,
    routes: [
      {
        path: '/experts-list',
        title: 'experts-list',
        component: lazy(
          () => import(/* webpackChunkName: 'ExpertsList' */ 'pages/Members/experts-list'),
        ),
      },
      {
        path: '/doctors-list',
        title: 'doctors-list',
        component: lazy(
          () => import(/* webpackChunkName: 'DoctorsList' */ 'pages/Members/doctors-list'),
        ),
      },
      {
        path: '/visitors-list',
        title: 'visitors-list',
        component: lazy(
          () => import(/* webpackChunkName: 'VisitorsList' */ 'pages/Members/visitors-list'),
        ),
      },
      {
        path: '/admin-users',
        title: 'admin-users',
        component: lazy(
          () => import(/* webpackChunkName: 'DoctorsList' */ 'pages/Members/admin-users'),
        ),
      },
      {
        path: '/messages',
        title: 'chat-messages',
        component: lazy(
          () => import(/* webpackChunkName: 'ChatMessages' */ 'pages/Members/chat-messages'),
        ),
      },
    ],
  },
  {
    path: '/pharma',
    title: 'pharma-companies',
    icon: LocalPharmacyIcon,
    routes: [
      {
        path: '/index',
        title: 'pharma-companies-list',
        component: lazy(() => import(/* webpackChunkName: 'Pharma' */ 'pages/PharmaList')),
      },
    ],
  },
  {
    path: '/articles',
    title: 'articles',
    icon: AssignmentIcon,
    routes: [
      {
        path: '/index',
        title: 'members-articles',
        component: lazy(() => import(/* webpackChunkName: 'Articles' */ 'pages/ArticlesMember')),
      },
      {
        path: '/articles',
        title: 'pharma-company-articles',
        component: lazy(() => import(/* webpackChunkName: 'Articles' */ 'pages/ArticlesCompany')),
      },
    ],
  },
  {
    path: '/events',
    title: 'events',
    icon: EventIcon,
    private: true,
    routes: [
      {
        path: '/index',
        private: true,
        title: 'medical-events',
        component: lazy(
          () => import(/* webpackChunkName: 'MedicalEvents' */ 'pages/MedicalEvents'),
        ),
      },
    ],
  },
  {
    path: '/surveys',
    title: 'surveys',
    icon: ListAltIcon,
    routes: [
      {
        path: '/index',
        title: 'surveys-list',
        component: lazy(() => import(/* webpackChunkName: 'Surveys' */ 'pages/SurveysList')),
      },
    ],
  },
  {
    path: '/campaigns',
    title: 'campaigns',
    icon: TrackChangesIcon,
    routes: [
      {
        path: '/index',
        title: 'campaigns',
        component: lazy(() => import(/* webpackChunkName: 'Campaigns' */ 'pages/Campaigns')),
      },
      {
        path: '/email-templates',
        title: 'email-templates',
        component: lazy(
          () => import(/* webpackChunkName: 'EmailTemplates' */ 'pages/EmailTemplates'),
        ),
      },
      {
        path: '/sms-messages',
        title: 'sms-messages',
        component: lazy(() => import(/* webpackChunkName: 'SMSContents' */ 'pages/SMSContents')),
      },
      {
        path: '/email-service-providers',
        title: 'email-service-providers',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'EmailServiceProviders' */ 'pages/EmailServiceProviders'),
        ),
      },
    ],
  },
  {
    path: '/app-settings',
    title: 'app-settings',
    icon: SettingsApplicationsIcon,
    permission: PERMISSIONS.EDIT_WEBSITES,
    routes: [
      {
        path: '/tags',
        title: 'tags',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/Tags')),
      },
      {
        path: '/countries',
        title: 'countries',
        component: lazy(() => import(/* webpackChunkName: 'Countries' */ 'pages/Countries')),
      },
      {
        path: '/cities',
        title: 'cities',
        component: lazy(() => import(/* webpackChunkName: 'Cities' */ 'pages/Cities')),
      },
      {
        path: '/states',
        title: 'states',
        component: lazy(() => import(/* webpackChunkName: 'States' */ 'pages/States')),
      },
      {
        path: '/taxonomies',
        title: 'taxonomies',
        component: lazy(() => import(/* webpackChunkName: 'Taxonomies' */ 'pages/Taxonomies')),
      },
      {
        path: '/hospitals',
        title: 'hospitals',
        component: lazy(() => import(/* webpackChunkName: 'Hospitals' */ 'pages/Hospitals')),
      },
      {
        path: '/title-of-names',
        title: 'title-of-names',
        component: lazy(() => import(/* webpackChunkName: 'TitleOfNames' */ 'pages/TitleOfNames')),
      },
      {
        path: '/speak-languages',
        title: 'speak-languages',
        component: lazy(
          () => import(/* webpackChunkName: 'SpeakLanguages' */ 'pages/SpeakLanguages'),
        ),
      },
      {
        path: '/email-subjects',
        title: 'email-subjects',
        component: lazy(
          () => import(/* webpackChunkName: 'EmailSubjects' */ 'pages/EmailSubjects'),
        ),
      },
      {
        path: '/article-categories',
        title: 'article-categories',
        component: lazy(
          () => import(/* webpackChunkName: 'ArticleCategories' */ 'pages/ArticleCategories'),
        ),
      },
    ],
  },
  {
    path: '/cms',
    title: 'cms',
    icon: AnnouncementIcon,
    routes: [
      {
        path: '/expert',
        title: 'experts-landing-page',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/expert'),
        ),
      },
      {
        path: '/pharma',
        title: 'page-pharma-company',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/pharma'),
        ),
      },
      {
        path: '/privacy',
        title: 'page-privacy',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/privacy'),
        ),
      },
      {
        path: '/terms',
        title: 'page-terms',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/terms'),
        ),
      },
      {
        path: '/app',
        title: 'page-app',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/app'),
        ),
      },
    ],
  },
  {
    path: '/settings',
    title: 'settings',
    icon: SettingsIcon,
    permission: PERMISSIONS.EDIT_SETTINGS,
    routes: [
      {
        path: '/users',
        title: 'users-crm',
        component: lazy(() => import(/* webpackChunkName: 'Users' */ 'pages/Users')),
      },
      {
        path: '/permissions',
        title: 'permissions',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(() => import(/* webpackChunkName: 'Permissions' */ 'pages/Permissions')),
      },
      {
        path: '/site-parameters',
        title: 'site-parameters',
        component: lazy(
          () => import(/* webpackChunkName: 'SiteParameters' */ 'pages/SiteParameters'),
        ),
      },
      {
        path: '/external-channels',
        title: 'external-channels',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(
          () => import(/* webpackChunkName: 'ExternalChannels' */ 'pages/ExternalChannels'),
        ),
      },
      {
        path: '/labels-crm',
        title: 'labels-crm',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Labels/crm')),
      },
      {
        path: '/labels-expert',
        title: 'labels-expert',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Labels/expert')),
      },
      {
        path: '/labels-web',
        title: 'labels-website',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Labels/websites')),
      },
      {
        path: '/expert-types',
        title: 'expert-types',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(() => import(/* webpackChunkName: 'ExpertTypes' */ 'pages/ExpertTypes')),
      },
    ],
  },
  {
    path: '/profile',
    title: 'profile',
    inMenu: false,
    component: lazy(() => import(/* webpackChunkName: 'Profile' */ 'pages/Profile')),
  },
  {
    path: '/login',
    title: 'login',
    inMenu: false,
    private: false,
    inLayout: false,
    component: lazy(() => import(/* webpackChunkName: 'Login' */ 'pages/Login')),
  },
];

export default routes;
