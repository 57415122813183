import { memo, ReactNode, useEffect } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { AppThemeOptions } from 'styles/theme';
import { useIsRtl } from 'hooks';

export const themeRTL = createTheme(AppThemeOptions, { direction: 'rtl' });
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

export const themeLTR = createTheme(AppThemeOptions, { direction: 'ltr' });
const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
  prepend: true,
});

interface Props {
  children: ReactNode;
}

export const AppThemeProvider = memo<Props>(({ children }) => {
  const isRTL = useIsRtl();
  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  }, [isRTL]);

  const theme = isRTL ? themeRTL : themeLTR;
  const cache = isRTL ? cacheRtl : cacheLtr;

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
});
