import { ThemeOptions } from '@mui/material';
import variables from 'styles/config.module.scss';

const FontSize = '1.4rem';

const breakpoints = {
  values: {
    xs: Number(variables.xs),
    sm: Number(variables.sm),
    md: Number(variables.md),
    lg: Number(variables.lg),
    xl: Number(variables.xl),
  },
};

export const AppThemeOptions: ThemeOptions = {
  breakpoints: breakpoints,
  spacing: 10,
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    fontFamily: variables.fontMain,
    h1: {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    h3: {
      fontWeight: 600,
      textTransform: 'uppercase',
    },

    h4: {
      fontSize: '2.4rem',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    h5: {
      fontSize: '2rem',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    body1: {
      fontSize: FontSize,
    },
    button: {
      fontSize: '1.2rem',
    },
  },
  palette: {
    text: {
      primary: variables.colorTextPrimary,
    },
    primary: {
      main: variables.colorSecondary,
      contrastText: variables.colorWhite,
    },
    secondary: {
      main: variables.colorPrimary,
      contrastText: variables.colorWhite,
    },
  },
  components: {
    //    MuiTypography: {
    //       styleOverrides: {
    //         colorSecondary: {
    //           color: variables.colorSecondary,
    //         },
    //       },
    //     },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderColor: variables.colorBorder,
          borderWidth: 2,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          // color: variables.colorWhite,
          fontSize: '1.2rem',
          letterSpacing: 0.5,
        },
        iconSizeSmall: {
          '& > *:first-child': {
            fontSize: '1.8rem',
          },
        },
        textSecondary: {
          color: variables.colorSecondary,
        },
        textPrimary: {
          color: variables.colorPrimary,
        },
        sizeSmall: {
          padding: '0.4rem 1rem',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '1.2rem',
        },
        sizeSmall: {
          padding: '0.2rem',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.MuiGrid-spacing-xs-4': {
            width: 'auto',
            margin: '-10px',
          },
          '&.MuiGrid-spacing-xs-4 > .MuiGrid-item': {
            padding: '10px',
          },
        },
        item: {
          width: '100%',
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
        },
        container: {
          marginTop: '0 !important',
          marginBottom: '0 !important',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '1rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '1rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflowY: 'inherit',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '1rem',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        size: 'small',
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0 0 1.5rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: 'translate(0, .4rem)',
          fontSize: '1.2rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: FontSize,
          color: variables.colorTable,
          '&.Mui-focused': {
            color: variables.colorTable,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          color: variables.colorBorder,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: FontSize,
        },
        underline: {
          '&:before': {
            borderBottomColor: variables.colorBorder,
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          fontSize: FontSize,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 1301,
        },
      },
    },
    // MuiPickerDTTabs: {
    //   styleOverrides: {
    //     tabs: {
    //       color: '#fff',
    //     },
    //   },
    // },
    // MuiPickersToolbar: {
    //   styleOverrides: {
    //     toolbar: {
    //       backgroundColor: variables.colorSecondary,
    //     },
    //   },
    // },
    // MuiPickersDay: {
    //   styleOverrides: {
    //     daySelected: {
    //       backgroundColor: variables.colorSecondary,
    //     },
    //   },
    // },
    //@ts-ignore
    MuiPickersModal: {
      styleOverrides: {
        withAdditionalAction: {
          '& > .MuiButton-textPrimary': {
            color: variables.colorSecondary,
          },
        },
      },
    },
  },
};
