import { memo, useMemo, useState } from 'react';
import { isValid } from 'date-fns';
import { useI18n } from 'AurionCR/components/i18';
import { DateView, MobileDatePicker, MobileDateTimePicker, TimeView } from '@mui/x-date-pickers';
import Calendar from '@mui/icons-material/Event';
import CustomDatePickerHeader from '../components/custom-date-picker-header';
import CustomDateTimePickerToolBar from './components/app-time-date-picker-toolbar';
import style from './index.module.scss';
import CustomDatePickerToolBar from './components/app-date-picker-toolbar';

interface IDatePickerProps {
  label?: string;
  value?: any;
  error?: any | { message: string };
  format?: string;
  showTodayButton?: boolean;
  ampm?: boolean;
  helperText?: string;
  onChange: (e: object, value?: any) => void;
}

export const DatePicker = memo<IDatePickerProps>(
  ({
    value,
    error,
    format = 'dd/MM/yyyy HH:mm',
    showTodayButton = true,
    ampm = false,
    label,
    helperText,
    onChange,
    ...props
  }) => {
    const { t } = useI18n();
    const [dateView, setDateView] = useState<DateView>('day');
    const [dateTimeView, setTimeDateView] = useState<DateView | TimeView>('day');

    const value_ = useMemo(() => {
      if (value) {
        if (typeof value === 'string' && isValid(new Date(value))) {
          return new Date(value);
        } else if (value instanceof Date) {
          return value;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }, [value]);

    const isTimeAvailable = useMemo(() => {
      return /[hHm]/g.test(format);
    }, [format]);

    return (
      <div className="date-field-wrapper">
        {isTimeAvailable && (
          <MobileDateTimePicker
            {...props}
            label={label}
            value={value_}
            format={format}
            ampm={ampm}
            view={dateTimeView}
            views={['year', 'day', 'hours', 'minutes']}
            onViewChange={(view) => setTimeDateView(view)}
            onChange={(value) => onChange({ e: { target: { value: value } } }, value)}
            slots={{
              toolbar: (props) => (
                <CustomDateTimePickerToolBar {...props} setOpenView={setTimeDateView} />
              ),
              calendarHeader: CustomDatePickerHeader,
            }}
            slotProps={{
              mobilePaper: {
                className: style.paper,
              },
              textField: {
                error: Boolean(error),
                helperText: error && error.message ? t(error.message) || '' : helperText,
                fullWidth: true,
                InputProps: { endAdornment: <Calendar style={{ cursor: 'pointer' }} /> },
              },
              actionBar: {
                actions: ['today', 'cancel', 'accept'],
              },
            }}
            localeText={{
              cancelButtonLabel: t('cancel'),
              todayButtonLabel: t('today'),
              okButtonLabel: t('ok'),
            }}
          />
        )}
        {!isTimeAvailable && (
          <MobileDatePicker
            {...props}
            label={label}
            value={value_}
            format={format}
            view={dateView}
            onViewChange={(view) => setDateView(view)}
            onChange={(value) => onChange({ e: { target: { value: value } } }, value)}
            slots={{
              toolbar: (props) => <CustomDatePickerToolBar {...props} setOpenView={setDateView} />,
              calendarHeader: CustomDatePickerHeader,
            }}
            slotProps={{
              mobilePaper: {
                className: style.paper,
              },
              textField: {
                error: Boolean(error),
                helperText: error && error.message ? t(error.message) || '' : helperText,
                fullWidth: true,
                InputProps: { endAdornment: <Calendar style={{ cursor: 'pointer' }} /> },
              },
              actionBar: {
                actions: ['today', 'cancel', 'accept'],
              },
            }}
            localeText={{
              cancelButtonLabel: t('cancel'),
              todayButtonLabel: t('today'),
              okButtonLabel: t('ok'),
            }}
          />
        )}
      </div>
    );
  },
);

export default DatePicker;
