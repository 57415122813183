import { forwardRef, memo, Ref, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
// @ts-ignore
import MaskedInput from 'react-text-mask';

export const TimeDuration = memo(
  forwardRef(({ onChange, onBlur, onEscape, value, className, ...rest }: any, ref: Ref<any>) => {
    const input = useRef<any>(null);
    // handlers
    const onFocus = useCallback(() => {
      if (input && input.current && input.current.inputElement) input.current.inputElement.focus();
    }, [input]);
    // public
    useImperativeHandle(ref, () => ({ focus: onFocus }));
    // init
    useEffect(() => {
      let timer: any;
      if (rest.autoFocus) timer = setTimeout(onFocus, 0);
      return () => {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onFocus]);
    // render
    return (
      <MaskedInput
        {...rest}
        ref={input}
        className={`duration ${className || ''}`}
        value={value}
        mask={(val: any) => {
          if (val[0] === '2') {
            return [/[0-2]/, /[0-3]/, ':', /[0-5]/, /[0-9]/];
          } else {
            return [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
          }
        }}
        onChange={onChange}
        onBlur={(e: any) => {
          let val = e.target.value;
          if (!val) {
            val = '00:00';
          } else {
            val = val.replace(/_/g, '0');
          }
          onChange({ target: { value: val } });
          if (onBlur) onBlur({ target: { value: val } });
        }}
        onKeyUp={(e: any) => {
          if (e.key === 'Escape') {
            if (onEscape) onEscape(e);
            else e.target.blur();
          }
          if (e.key === 'Enter') e.target.blur();
        }}
      />
    );
  }),
);

export default TimeDuration;
