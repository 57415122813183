import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import notify from 'AurionCR/store/modules/notify';
import routes from 'AurionCR/store/modules/routes';
import sources from 'AurionCR/store/modules/sources';
import language from 'AurionCR/store/modules/language';
import auth from 'AurionCR/store/modules/auth';

import sourceSaga from 'AurionCR/store/sagas/source';
import { authSaga } from 'store/auth';

const config = {
  store: { auth: auth, notify, routes, sources, language },
  sagas: [...authSaga, ...sourceSaga],
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const rootReducer = combineReducers(config.store);
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

function* rootSaga() {
  yield all(config.sagas);
}

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
