import { IconButton, TextField } from '@mui/material';
import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { useI18n } from '../i18';
import ClearIcon from '@mui/icons-material/Clear';

export interface InputProps {
  onClear?: () => void;
  value: string;
  error?: Error | undefined;

  [x: string]: any;
}

export interface InputRefProps {
  inputRef: React.MutableRefObject<any>;
  focus: () => void;
}

export const Input = memo(
  forwardRef<InputRefProps, InputProps>(({ onClear, ...rest }, ref) => {
    const { t } = useI18n();
    const inputRef = useRef<any>(null);
    const { disableClearable, ...props } = rest;
    // handlers
    const clear = useCallback(() => {
      if (inputRef) {
        let input = inputRef.current;
        let lastValue = input.value;
        input.value = '';
        let event = new Event('input', { bubbles: true });
        let tracker = input._valueTracker;
        if (tracker) tracker.setValue(lastValue);
        input.dispatchEvent(event);
      }
      if (typeof onClear === 'function') onClear();
    }, [onClear, inputRef]);
    // props
    const InputProps = useMemo(() => {
      return {
        ...props.InputProps,
        endAdornment: (
          <>
            {!props.disabled &&
              (!props.inputProps || !props.inputProps.disabled) &&
              !disableClearable &&
              (props.value || props.defaultValue) && (
                <IconButton
                  aria-label="delete"
                  className="btn-clear"
                  size="small"
                  onClick={clear}
                  tabIndex={-1}
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              )}
            {props.InputProps && props.InputProps.endAdornment}
          </>
        ),
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    // public
    useImperativeHandle(ref, () => ({
      inputRef,
      focus: () => {
        if (inputRef && inputRef.current) inputRef.current.focus();
      },
    }));
    return (
      <div className={`text-field-wrapper${props.value ? ' clear-available' : ''}`}>
        <TextField
          fullWidth
          {...props}
          error={Boolean(props.error)}
          helperText={
            props.error && props.error.message ? t(props.error.message) || '' : props.helperText
          }
          inputRef={inputRef}
          InputProps={InputProps}
        />
      </div>
    );
  }),
);
export default Input;
