import { isValid } from 'date-fns';

export const prepareDates = <T extends { [x: string]: any | Date }>(data: T) => {
  return Object.keys(data).reduce((acc, key) => {
    const value = data[key];
    // @ts-ignore
    acc[key] = value instanceof Date && isValid(value) ? new Date(value).toISOString() : value;
    return acc;
  }, {} as T);
};
export const prepareIDs = <T extends Record<string, any>>(data: T) => {
  return Object.keys(data).reduce((acc, key) => {
    const value = data[key];
    // @ts-ignore
    acc[key] = String(key).endsWith('ID') && value === '' ? null : value;
    return acc;
  }, {} as T);
};
