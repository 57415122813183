import React, { ChangeEvent, ReactNode, useCallback, useMemo } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { validateRule } from '../validation';

interface FormControllerProps<T> {
  name: keyof T;
  control: UseFormMethods['control'];
  as: (props: any) => ReactNode;
  rules?: any;
  errors?: any;
  onChange?: (e: any, value: any) => any;
  [x: string]: any;
}

function FormControllerComponent<FormModel = {}, AsProps = {}>({
  name,
  control,
  as: Element,
  rules,
  errors,
  onChange: onChangeUp,
  ...rest
}: FormControllerProps<FormModel> & AsProps) {
  const rules_ = useMemo(() => (rules ? validateRule(rules) || rules : undefined), [rules]);

  const onChangeProxy = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: any) => {
      if (onChangeUp) {
        return onChangeUp(e, value);
      } else {
        let value_ = '';
        if (
          e.target &&
          e.target.value !== undefined &&
          e.target.value !== null &&
          e.target.type !== 'checkbox'
        ) {
          value_ = e.target.value;
        } else if (value !== undefined && value !== null) {
          value_ = value;
        }
        return value_;
      }
    },
    [onChangeUp],
  );

  return (
    <Controller
      name={String(name)}
      control={control}
      rules={rules_}
      render={({
        onChange,
        onBlur,
        value,
        name,
      }: {
        onChange: any;
        onBlur: () => void;
        value: any;
        name: string;
      }) => (
        <Element
          {...rest}
          onBlur={onBlur}
          value={value}
          name={name}
          error={errors ? errors[name] : null}
          onChange={(e: ChangeEvent<HTMLInputElement>, value: any) =>
            onChange(onChangeProxy(e, value))
          }
        />
      )}
    />
  );
}

export const FormController = React.memo(FormControllerComponent) as typeof FormControllerComponent;
