import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

type Params = Record<string, any> | void;

export interface TypedRouteResult {
  config: { pathname: string; search: string | undefined };
  link: string;
}
export interface TypedRoute<T extends Params = void> {
  (params: T): TypedRouteResult;
  path: string;
}
export type ExtractParams<T = void> = T extends TypedRoute<infer P> ? Partial<Exclude<P, void>> : T;

export const useTypedRouteParams = <T>() => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const searchObject = useMemo(() => {
    return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
      // @ts-ignore
      acc[key] = value;
      return acc;
    }, {});
  }, [searchParams]);

  return { ...params, ...searchObject } as ExtractParams<T>;
};
