import React from 'react';

const Page404: React.FC = () => {
  return (
    <>
      <h1>Page: Page404</h1>
    </>
  );
};

export default Page404;
